.delete-file {
    color: #fff;
    font-size: 18px;
    padding: 2px 6px;
    background: #c70505;
    border-radius: 5px;
}
.campaign-list-table-cont .search-label{
	max-width: 300px;
    display: inline-block !important;
    margin-right: 18px;
}
.campaign-list-table-cont .export-csv {
	float:right;
}
.campaign-list-table-cont .dropdown-menu, #pageDropDown{
	background-color: #fff;
}

.campaignlist-table{
	background-color: #fff;
}

.detailed-report-link .fa{
margin-right: 6px;
}